import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Timetable from "../components/pages/organisationHome/timetable";
import Diary from "../components/pages/organisationHome/diary";
import BookingDetails from "../components/pages/organisationHome/bookingDetails";
import RoomDetails from "../components/pages/organisationHome/roomDetails";
import YourBookings from "../components/pages/organisationHome/yourBookings";

//Styles
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import { UpdateBookingDetails, UpdateBookingID } from "../store/actions/bookings";
import * as RoomDetailsActions from "../store/actions/roomDetails";
import * as UserProfileActions from "../store/actions/userProfile";
import * as BookingDataActions from "../store/actions/bookingData";

function OrganisationHome(props) {
	const organisationId = props.match.params.id;

	const organisation = useSelector((state) => state.organisation);
	const globalVars = useSelector((state) => state.globalVars);
	const bookings = useSelector((state) => state.bookings);
	const roomDetails = useSelector((state) => state.roomDetails);
	const user = useSelector((state) => state.user);
	const userProfile = useSelector((state) => state.userProfile);
	const preBuiltFeatures = useSelector((state) => state.preBuiltFeatures);
	const dispatch = useDispatch();
	const history = useHistory();

	const [settings, setSettings] = useState({
		orgTitle: "",
		orgLocked: "",
		roomName: "Rooms",
		roomLayout: "",
		roomID: "",
		layoutData: {},
		view: false,
		weekSystem: false,
		section: "Rooms",
	});

	const [features, setFeatures] = useState({
		yourBookings: false,
	});

	const [modal, setModal] = useState({
		open: false,
		heading: "",
		message: "",
	});

	const [modalYN, setModalYN] = useState({
		open: false,
		heading: "",
		message: "",
		acceptFunction: "",
		acceptName: "",
		showAccept: false,
		cancelName: "",
		showCancel: false,
	});

	function handleModalClose() {
		setModal((prevState) => {
			return { ...prevState, open: false };
		});
	}

	function handleModalYNClose() {
		setModalYN((prevState) => {
			return { ...prevState, open: false };
		});
	}

	useEffect(() => {
		document.title = "STAFF";
		if (!user.auth) {
			history.replace("/");
		}
		checkFeatures();
		CheckPreviousState();
	}, []);

	function checkFeatures() {
		setFeatures((prevState) => {
			return {
				...prevState,
				yourBookings: preBuiltFeatures.find((feature) => feature.name === "yourBookings").enabled === "true",
			};
		});
	}

	function CheckPreviousState() {
		if (globalVars.roomDetail.roomName != "") {
			setSettings((prevState) => {
				return {
					...prevState,
					roomName: globalVars.roomDetail.roomName,
					roomLayout: globalVars.roomDetail.layoutData.layout,
					layoutData: globalVars.roomDetail.layoutData,
					roomID: globalVars.roomDetail.roomID,
					weekSystem: globalVars.roomDetail.weekSystem,
				};
			});
			dispatch(RoomDetailsActions.UpdateShowSwitch(true));
			setTimeout(() => {
				setSettings((prevState) => {
					return { ...prevState, view: true };
				});
			}, 100);
		}
	}

	function handleRoomDropdownClick(name, layoutUUID, id, weekSystem) {
		setSettings((prevState) => {
			return { ...prevState, roomName: name, roomLayout: "---", roomID: id, weekSystem: false };
		});

		dispatch(UpdateBookingID(0));
		dispatch(RoomDetailsActions.UpdateId(id));
		dispatch(RoomDetailsActions.UpdateShowSwitch(true));

		let layoutData;
		for (const layout of organisation.layouts) {
			if (layout.uuid == layoutUUID) {
				layoutData = layout;
			}
		}

		setSettings((prevState) => {
			return { ...prevState, roomName: name, roomLayout: layoutData.layout, roomID: id, weekSystem: weekSystem == "true", layoutData: layoutData, view: false };
		});
		//USER PROFILE
		if (!userProfile.default) {
			UpdateRoomProfileSettings(id);
		}

		dispatch(UpdateBookingDetails(false));
		setTimeout(() => {
			setSettings((prevState) => {
				return { ...prevState, view: true };
			});
		}, 100);

		dispatch(BookingDataActions.SetDataSlots({}));
	}

	function UpdateRoomProfileSettings(roomUUID) {
		for (const room in userProfile.rooms) {
			if (userProfile.rooms[room].roomUUID == roomUUID) {
				dispatch(UserProfileActions.UpdateRoom_View(userProfile.rooms[room].prop_View == "true"));
				dispatch(UserProfileActions.UpdateRoom_Write(userProfile.rooms[room].prop_Write == "true"));
				dispatch(UserProfileActions.UpdateRoom_Edit(userProfile.rooms[room].prop_Edit == "true"));
				dispatch(UserProfileActions.UpdateRoom_Delete(userProfile.rooms[room].prop_Delete == "true"));
				dispatch(UserProfileActions.UpdateRoom_Repeat(userProfile.rooms[room].prop_Repeat == "true"));
			}
		}
	}

	function changeRoomSection(section) {
		setSettings((prevState) => {
			return { ...prevState, section: section };
		});
	}

	////////////////////////////////////////////////////////

	return (
		<div className="body">
			<Container fluid className="p-3">
				<Jumbotron className="back-color">
					<h1>{organisation.name}</h1>
					{organisation.locked ? <h2>Organisation Locked</h2> : null}
					<Row>
						<Col></Col>
						<Col xs={8}>
							{settings.section === "Rooms" ? (
								<div>
									<div className="roomLbl">Rooms:</div>
									<Dropdown className="rooms">
										<Dropdown.Toggle variant="primary" id="dropdown-layouts">
											{settings.roomName}
										</Dropdown.Toggle>
										<Dropdown.Menu className="dropdown-items">
											{organisation.rooms.map((room, index) => {
												return (
													<Dropdown.Item
														key={index}
														onClick={() => {
															handleRoomDropdownClick(room.name, room.layout, room.uuid, room.weekSystem);
														}}>
														{room.name}
													</Dropdown.Item>
												);
											})}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							) : null}
						</Col>
						<Col>
							{features.yourBookings ? (
								<div>
									<Dropdown>
										<Dropdown.Toggle>{settings.section}</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => changeRoomSection("Rooms")}>Rooms</Dropdown.Item>
											<Dropdown.Item onClick={() => changeRoomSection("Your Bookings")}>Your Bookings</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col></Col>
						<Col xs={8}>
							{settings.section === "Rooms" ? (
								<div>
									{settings.view ? (
										<div>
											{settings.roomLayout != "" && settings.roomLayout == "Timetable" ? (
												<Timetable orgID={organisationId} roomName={settings.roomName} roomID={settings.roomID} layoutData={settings.layoutData} weekSystem={settings.weekSystem} />
											) : null}
											{settings.roomLayout != "" && settings.roomLayout == "Diary" ? (
												<Diary orgID={organisationId} roomName={settings.roomName} roomID={settings.roomID} layoutData={settings.layoutData} weekSystem={settings.weekSystem} />
											) : null}
										</div>
									) : null}
								</div>
							) : null}
							{settings.section === "Your Bookings" ? (
								<div>
									<YourBookings />
								</div>
							) : null}
						</Col>
						<Col>
							<Row>
								<div className="row-nothing">
									<Button disabled>Test</Button>
								</div>
							</Row>
							<Row>
								<Col>
									{bookings.bookingDetails && <BookingDetails orgID={organisationId} />}
									{roomDetails.show && <RoomDetails />}
									<div></div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Jumbotron>
			</Container>
			<Modal show={modal.open} onHide={handleModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>{modal.heading}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modal.message}</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleModalClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={modalYN.open} onHide={handleModalYNClose}>
				<Modal.Header closeButton>
					<Modal.Title>{modalYN.heading}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modalYN.message}</Modal.Body>
				<Modal.Footer>
					{modalYN.showAccept ? (
						<div>
							<Button variant="primary" onClick={modalYN.acceptFunction}>
								{modalYN.acceptName}
							</Button>
						</div>
					) : null}
					{modalYN.showCancel ? (
						<div>
							<Button variant="primary" onClick={handleModalYNClose}>
								{modalYN.cancelName}
							</Button>
						</div>
					) : null}
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default OrganisationHome;
