import React from 'react';

function BetaBanner() {

    return (
        <div className='betaBanner'>
            <p><strong>This website is a beta verison, please expect errors. Any problems, please contact High-View Studios (Shaun Evans)</strong></p>
        </div>
    )
}

export default BetaBanner;