//NEW USER PROFILE SETTING - place the new setting in the 'initialState'. If you are adding a new section, remember to add it under 'DEFAULT_SETTINGS and 'UPDATE_USER_SETTINGS

import * as ReducerActions from "../actions/userProfile";
import DefaultAccessLevels from "../../models/accessLevelDefaultSettings";

const initialState = {
	default: false,
	rooms: [],
	room_View: false,
	room_Write: false,
	room_Edit: false,
	room_Delete: false,
	room_Repeat: false,
	userSettings: {
		bookings: {
			viewAllUsers: false,
			viewAllDepartments: false,
		},
		administrator: {
			orgAdminAccess: false,
			admin_loginSettings: false,
			admin_departments: false,
			admin_users: false,
			admin_rooms: false,
			admin_layouts: false,
			admin_weekSystemHolidays: false,
			admin_userProfiles: false,
		},
		weeks: {
			goBackWeeks: "",
		},
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ReducerActions.DEFAULT_SETTINGS: {
			if (action.role === "") {
				action.role = "user";
			}

			let userSettings = state.userSettings;
			userSettings.bookings = DefaultAccessLevels[action.role].bookings;
			userSettings.administrator = DefaultAccessLevels[action.role].administrator;
			userSettings.weeks = DefaultAccessLevels[action.role].weeks;

			return {
				...state,
				default: true,
				room_View: DefaultAccessLevels[action.role].room_View,
				room_Write: DefaultAccessLevels[action.role].room_Write,
				room_Edit: DefaultAccessLevels[action.role].room_Edit,
				room_Delete: DefaultAccessLevels[action.role].room_Delete,
				room_Repeat: DefaultAccessLevels[action.role].room_Repeat,
				userSettings: userSettings,
			};
		}
		case ReducerActions.UPUPDATE_ROOM_VIEW:
			return { ...state, room_View: action.value };

		case ReducerActions.UPUPDATE_ROOM_WRITE:
			return { ...state, room_Write: action.value };

		case ReducerActions.UPUPDATE_ROOM_EDIT:
			return { ...state, room_Edit: action.value };

		case ReducerActions.UPUPDATE_ROOM_DELETE:
			return { ...state, room_Delete: action.value };

		case ReducerActions.UPUPDATE_ROOM_REPEAT:
			return { ...state, room_Repeat: action.value };

		case ReducerActions.UPDATE_USER_SETTINGS: {
			let settings = state.userSettings;
			settings.bookings = action.bookings;
			settings.administrator = action.administrator;
			settings.weeks = action.weeks;

			return { ...state, default: false, rooms: action.rooms, userSettings: settings };
		}
		case ReducerActions.RESET_STATE: {
			return { ...initialState, userSettings: action.defaultUserSettings };
		}
		default:
			return state;
	}
};
