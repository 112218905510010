//NEW USER PROFILE SETTING = place the new setting ( default value and type) in 'initialstate'. Make sure the name is the same as in the database! If you are adding a new section, remember
//to add it under 'SET_SETTINGS'

import * as ReducerActions from "../../actions/globalSettings/userProfileAdminGlobalSettings";

const initialState = {
	bookings: {
		viewAllUsers: { type: "Boolean", value: false },
		viewAllDepartments: { type: "Boolean", value: false },
	},
	administrator: {
		orgAdminAccess: { type: "Boolean", value: false },
		admin_loginSettings: { type: "Boolean", value: false },
		admin_departments: { type: "Boolean", value: false },
		admin_users: { type: "Boolean", value: false },
		admin_rooms: { type: "Boolean", value: false },
		admin_layouts: { type: "Boolean", value: false },
		admin_weekSystemHolidays: { type: "Boolean", value: false },
		admin_userProfiles: { type: "Boolean", value: false },
	},
	weeks: {
		goBackWeeks: { type: "Text", value: "" },
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ReducerActions.SET_SETTINGS:
			return {
				...state,
				bookings: action.values.bookings,
				administrator: action.values.administrator,
				weeks: action.values.weeks,
			};
		case ReducerActions.UPDATE_SETTING: {
			const section = state[action.section];
			section[action.setting].value = action.value;
			return { ...state, [action.section]: section };
		}
		case ReducerActions.UPDATE_ADMIN_SETTINGS: {
			const admin = state.administrator;
			admin.orgAdminAccess.value = action.mainSetting;
			admin[action.setting].value = action.value;
			return { ...state, administrator: admin };
		}
		default:
			return state;
	}
};
