import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Container, Dropdown, Modal, Button, Form } from "react-bootstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";

function OrganisationItem(props) {
	const history = useHistory();

	function handleDoubleClick(orgID) {
		history.push(`/administrator/organisationDetails/${orgID}`);
	}

	return (
		<div>
			<Card body className="Organisations-ListBox" onDoubleClick={handleDoubleClick.bind(this, props.orgID)}>
				<Row>
					<Col>
						<label>{props.name}</label>
					</Col>
					<Col>
						<label>{props.email}</label>
					</Col>
					<Col>
						<label>{props.poc}</label>
					</Col>
					<Col>
						<label>{props.orgID}</label>
					</Col>
				</Row>
			</Card>
		</div>
	);
}

export default OrganisationItem;
